<template>
  <div class="supplier-cost-pop common-pop">
    <el-dialog
      :title="todo == 'view' ? '【查看】订单费用信息' : '【编辑】订单费用信息'"
      :close-on-click-modal="false"
      width="80%"
      :visible.sync="visible">
      <div class="modularLabel modularLabel1">
        <label><span class="separate">|</span>订单信息</label>
        <label>（装货图片<span @click="previewByOrderId(loadGoodsTypeList)" style="color: blue;cursor:pointer">预览 </span></label>
        <label>签收单<span @click="previewByOrderId(successTypeList)" style="color: blue;cursor:pointer">预览</span>）</label>
      </div>
      <div class="modularBox modularBox1">
        <el-form :model="dataForm" ref="dataForm" label-width="96px" size="small">
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="订单编号">
                <el-input v-model="dataForm.orderInfo.orderNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单类型" prop="orderInfo.orderType">
                <el-select class="item-choose" v-model="dataForm.orderInfo.orderType" :disabled="true" style="width: 100%;">
                  <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="实际车型" prop="orderInfo.vehicleType">
                <el-select class="item-choose" v-model="dataForm.orderInfo.vehicleType" :disabled="true" style="width: 100%;">
                  <el-option v-for="item in dictTypeMap.vehicle_type" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="应付车型">
                <el-input v-model="dataForm.vehiclePlan.vehicleTypeName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="提货时间">
                <el-input v-model="dataForm.orderInfo.takeGoodsDate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="车次号">
                <el-input v-model="dataForm.vehiclePlan.planNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="进仓单号">
                <el-input v-model="dataForm.orderInfo.inWarehouseNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50" v-for="(item) in dataForm.orderInfo.addressList" :key="item.id">
            <el-col :span="24">
              <el-form-item :label="item.type === 1 ? '提货方' : item.type === 2 ? '卸货方' : '报关方'">
                <el-col :span="8">
                  <el-input v-model="item.factoryName" :disabled="true"></el-input>
                </el-col>
                <el-col :span="16">
                  <el-input v-model="item.fullAddress" :disabled="true"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="供应商名称" prop="customerId">
                <el-input v-model="dataForm.vehiclePlan.motorcadeName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机车牌">
                <el-input v-model="dataForm.vehiclePlan.vehiclePlate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机姓名">
                <el-input v-model="dataForm.vehiclePlan.driverName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机电话">
                <el-input v-model="dataForm.vehiclePlan.driverPhone" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="件数">
                <el-input v-model="dataForm.orderInfo.orderGoods.pieceNum" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="重量">
                <el-input v-model="dataForm.orderInfo.orderGoods.weight" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="体积">
                <el-input v-model="dataForm.orderInfo.orderGoods.volume" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
              <el-col :span="6">
                <el-form-item label="客户单号">
                  <el-input v-model="dataForm.orderInfo.customerSelfNo" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="车次创建时间">
                <el-input v-model="dataForm.vehiclePlan.createTime" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="车次创建人">
                <el-input v-model="dataForm.vehiclePlan.createId" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="异常备注">
                <el-input v-model="dataForm.orderInfo.groupExplain" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="24">
              <el-steps :active="statusActive" align-center finish-status="success">
                <el-step title="派车时间" :description="dispatchTime"></el-step>
                <el-step title="出车时间" :description="outDriveTime"></el-step>
                <el-step title="装货到场时间" :description="arrivedTime"></el-step>
                <el-step title="装货离场时间" :description="loadGoodsTime"></el-step>
                <el-step title="卸货到场时间" :description="arrivedGiveTime"></el-step>
                <el-step title="签收时间" :description="successTime"></el-step>
              </el-steps>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="modularLabel modularLabel2">
        <label v-if="allWinVehicle"><span class="separate">|</span>客户费用（应付/共赢）</label>
        <label v-else><span class="separate">|</span>客户费用（应付）</label>
      </div>
      <div class="modularBox modularBox2">
        <el-form :model="dataForm" ref="dataForm" :rules="rules" label-width="100px" size="small">
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="金额小计" prop="auditTotalMoney">
                <el-input v-model="dataForm.auditTotalMoney" readonly="readonly"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运费" prop="freightFee">
                <el-input v-model="dataForm.freightFee" @input="countMoney()" :disabled="isViews || dataForm.payRequestConfig.freightFeeBox" placeholder="运费">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="押夜超时费" prop="overtimeFee">
                <el-input v-model="dataForm.overtimeFee" @input="countMoney()" :disabled="isViews || dataForm.payRequestConfig.overtimeFeeBox" placeholder="押夜超时费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.overtimeFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加点费" prop="addFee1">
                <el-input v-model="dataForm.addFee" @input="countMoney(2)" :disabled="isViews || dataForm.payRequestConfig.addFeeBox" placeholder="加点费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.addFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="过磅费" prop="weighFee">
                <el-input v-model="dataForm.weighFee" @input="countMoney()" :disabled="isViews || dataForm.payRequestConfig.weighFeeBox" placeholder="过磅费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.weighFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="代支装卸费" prop="loadFee">
                <el-input v-model="dataForm.loadFee" @input="countMoney()" :disabled="isViews || dataForm.payRequestConfig.loadFeeBox" placeholder="代支装卸费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.loadFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报关/入仓费" prop="entranceFee">
                <el-input v-model="dataForm.entranceFee" @input="countMoney()" :disabled="isViews || dataForm.payRequestConfig.entranceFeeBox" placeholder="报关/入仓费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.entranceFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="停车费" prop="parkingFee">
                <el-input v-model="dataForm.parkingFee" @input="countMoney(2)" :disabled="isViews || dataForm.payRequestConfig.parkingFeeBox" placeholder="停车费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.parkingFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="高速费" prop="highFee">
                <el-input v-model="dataForm.highFee" @input="countMoney()" :disabled="isViews || dataForm.payRequestConfig.highFeeBox" placeholder="高速费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.highFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="打印费" prop="printFee">
                <el-input v-model="dataForm.printFee" @input="countMoney(2)" :disabled="isViews || dataForm.payRequestConfig.printFeeBox" placeholder="打印费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.printFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="维修费" prop="maintainFee">
                <el-input v-model="dataForm.maintainFee" @input="countMoney(2)" :disabled="isViews || dataForm.payRequestConfig.maintainFeeBox" placeholder="加点费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.maintainFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="现金加油费" prop="printFee">
                <el-input v-model="dataForm.refuelFee" @input="countMoney(2)" :disabled="isViews || dataForm.payRequestConfig.refuelFeeBox" placeholder="现金加油费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.refuelFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="现金过路费" prop="passingFee">
                <el-input v-model="dataForm.passingFee" @input="countMoney(2)" :disabled="isViews || dataForm.payRequestConfig.passingFeeBox" placeholder="现金过路费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.passingFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="油卡费" prop="refuelCardFee">
                <el-input v-model="dataForm.refuelCardFee" @input="countMoney(2)" :disabled="isViews" placeholder="油卡费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.refuelCardFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="粤通卡费" prop="passingCardFee">
                <el-input v-model="dataForm.passingCardFee" @input="countMoney(2)" :disabled="isViews" placeholder="粤通卡费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.passingCardFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费用" prop="otherFee1">
                <el-input v-model="dataForm.otherFee" @input="countMoney(2)" :disabled="isViews || dataForm.payRequestConfig.otherFeeBox" placeholder="其他费用">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.otherFee)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="平台代垫" prop="platformPrepayment">
                <el-input v-model="dataForm.platformPrepayment" :disabled="true" placeholder="平台预付代垫"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机代垫" prop="driverPrepayment">
                <el-input v-model="dataForm.driverPrepayment" :disabled="true" placeholder="司机预付代垫"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="其他费用备注" prop="otherFeeRemark1">
                <el-input v-model="dataForm.otherFeeRemark" :disabled="isViews" placeholder="其他费用备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="司机借支" prop="driverBorrowing">
                <el-input v-model="dataForm.driverBorrowing" :disabled="isViews || dataForm.payRequestConfig.driverBorrowingBox" placeholder="司机借支">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="扣款" prop="deductionsFee">
                <el-input v-model="dataForm.deductionsFee" :disabled="isViews || dataForm.payRequestConfig.deductionsFeeBox" placeholder="社保/出险扣款">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="借支/扣款备注" prop="borrowingRemark">
                <el-input v-model="dataForm.borrowingRemark" :disabled="isViews" placeholder="社保扣款/出险扣款备注">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo !== 'view'">确定</el-button>
      </span>
    </el-dialog>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto" :limit="1"></view-image>
  </div>
</template>

<script>
import viewImage from '@/views/commonPop/viewImage-fy'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        orderInfo: {
          orderGoods: {}
        },
        vehiclePlan: {}
      },
      costInfoData: {},
      allWinVehicle: false,
      areaList: [],
      imgTypeList: [{
        entranceFee: [7050], // 报关/入仓
        highFee: [7051], // 高速费
        weighFee: [7052], // 过磅费
        loadFee: [7053], // 装卸费
        overtimeFee: [7054], // 押夜超时费
        takeFee: [7055], // 提货费
        addFee: [7056], // 加点费
        // 其他费用
        otherFee: [7057],
        // 加油费
        refuelFee: [7001],
        // 过路费
        passingFee: [7002],
        // 打印费
        printFee: [7003],
        // 维修费
        maintainFee: [7004],
        parkingFee: [7008],
        refuelCardFee: [7006],
        passingCardFee: [7007]
      }],
      rules: {
        freightFee: this._Valid.canNullinit(['nullOrPrice']),
        entranceFee: this._Valid.canNullinit(['nullOrPrice']),
        highFee: this._Valid.canNullinit(['nullOrPrice']),
        weighFee: this._Valid.canNullinit(['nullOrPrice']),
        loadFee: this._Valid.canNullinit(['nullOrPrice']),
        overtimeFee: this._Valid.canNullinit(['nullOrPrice']),
        takeFee: this._Valid.canNullinit(['nullOrPrice']),
        addFee: this._Valid.canNullinit(['nullOrPrice']),
        parkingFee: this._Valid.canNullinit(['nullOrPrice']),
        otherFee: this._Valid.canNullinit(['nullOrPrice']),
        printFee: this._Valid.canNullinit(['nullOrPrice']),
        maintainFee: this._Valid.canNullinit(['nullOrPrice']),
        refuelFee: this._Valid.canNullinit(['nullOrPrice']),
        passingFee: this._Valid.canNullinit(['nullOrPrice']),
        refuelCardFee: this._Valid.canNullinit(['nullOrPrice']),
        passingCardFee: this._Valid.canNullinit(['nullOrPrice'])
      },
      todo: 'view',
      writeStatus: 2,
      statusActive: 1,
      dispatchTime: null,
      outDriveTime: null,
      arrivedTime: null,
      loadGoodsTime: null,
      arrivedGiveTime: null,
      successTime: null,
      successTypeList: [6010],
      loadGoodsTypeList: [6011]
    }
  },
  components: {
    viewImage
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isViews () {
      return this.todo === 'view' || this.writeStatus === 1
    }
  },
  methods: {
    clearOldValue () {
      this.statusActive = 1
      this.dispatchTime = null
      this.outDriveTime = null
      this.arrivedTime = null
      this.loadGoodsTime = null
      this.arrivedGiveTime = null
      this.successTime = null
      this.wageBase = null
    },
    init (type, todo, row) {
      this.visible = true
      this.todo = todo || 'view'
      this.dataForm = row
      this.writeStatus = row.costInfo.writeStatus
      if (!this.dataForm.orderInfo.orderGoods) {
        this.dataForm.orderInfo.orderGoods = {}
      }
      this.costInfoData = JSON.parse(JSON.stringify(row))
      this.$http({
        url: this.$http.adornUrl(`/costInfo/orderCost/${row.costInfo.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.dataForm = data
        this.dataForm.orderInfo.takeGoodsDate = data.orderInfo.takeGoodsDate + ' ' + data.orderInfo.takeGoodsTime
        this.allWinVehicle = this.dataForm.vehiclePlan.allWinVehicle === 1
        let recordList = data.orderInfo.recordList
        this.dispatchTime = data.orderInfo.dispatchTime
        if (recordList) {
          for (let i = 0; i < recordList.length; i++) {
            let record = recordList[i]
            if (record.status === 5) {
              this.outDriveTime = record.operationTime
              this.statusActive = 2
            } else if (record.status === 6) {
              this.arrivedTime = record.operationTime
              this.statusActive = 3
            } else if (record.status === 7) {
              this.loadGoodsTime = record.operationTime
              this.statusActive = 4
            } else if (record.status === 15) {
              this.arrivedGiveTime = record.operationTime
              this.statusActive = 5
            }
          }
        }
        if (data.orderInfo.status === 8) {
          this.successTime = data.orderInfo.successTime
          this.statusActive = 6
        }
      })
    },
    // 图片预览
    preview (typeList) {
      this.$refs.viewPhoto.init(this.costInfoData.costInfo.id, typeList)
    },
    previewByOrderId (typeList) {
      this.$refs.viewPhoto.init(this.dataForm.orderInfo.id, typeList)
    },
    getDefaultValue (value, defaultValue) {
      if (value === undefined || value === null) {
        return defaultValue
      }
      let number = Number(value)
      if (number === undefined || number === null) {
        return defaultValue
      }
      return number
    },
    countMoney () {
      this.dataForm.auditTotalMoney = this.getDefaultValue(this.dataForm.freightFee, 0) +
        this.getDefaultValue(this.dataForm.entranceFee, 0) +
        this.getDefaultValue(this.dataForm.highFee, 0) +
        this.getDefaultValue(this.dataForm.weighFee, 0) +
        this.getDefaultValue(this.dataForm.loadFee, 0) +
        this.getDefaultValue(this.dataForm.overtimeFee, 0) +
        this.getDefaultValue(this.dataForm.takeFee, 0) +
        this.getDefaultValue(this.dataForm.parkingFee, 0) +
        this.getDefaultValue(this.dataForm.addFee, 0) +
        this.getDefaultValue(this.dataForm.otherFee, 0) +
        this.getDefaultValue(this.dataForm.printFee, 0) +
        this.getDefaultValue(this.dataForm.maintainFee, 0) +
        this.getDefaultValue(this.dataForm.refuelFee, 0) +
        this.getDefaultValue(this.dataForm.passingFee, 0) +
        this.getDefaultValue(this.dataForm.refuelCardFee, 0) +
        this.getDefaultValue(this.dataForm.passingCardFee, 0)
      // 计算代垫
      let platformPrepayment = this.getDefaultValue(this.dataForm.refuelCardFee, 0) + this.getDefaultValue(this.dataForm.passingCardFee, 0)
      if (platformPrepayment > 0) {
        this.dataForm.platformPrepayment = platformPrepayment
      } else {
        this.dataForm.platformPrepayment = ''
      }
      let driverPrepayment = this.getDefaultValue(this.dataForm.weighFee, 0) +
        this.getDefaultValue(this.dataForm.entranceFee, 0) +
        this.getDefaultValue(this.dataForm.parkingFee, 0) +
        this.getDefaultValue(this.dataForm.loadFee, 0) +
        this.getDefaultValue(this.dataForm.highFee, 0) +
        this.getDefaultValue(this.dataForm.printFee, 0) +
        this.getDefaultValue(this.dataForm.maintainFee, 0) +
        this.getDefaultValue(this.dataForm.refuelFee, 0) +
        this.getDefaultValue(this.dataForm.passingFee, 0)
      if (driverPrepayment > 0) {
        this.dataForm.driverPrepayment = driverPrepayment
      } else {
        this.dataForm.driverPrepayment = ''
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/costInfo/update/orderCost`),
            method: 'POST',
            data: this.$http.adornData({
              'id': this.dataForm.id,
              'status': this.dataForm.status,
              'costType': 2,
              'freightFee': this.dataForm.freightFee,
              'entranceFee': this.dataForm.entranceFee,
              'highFee': this.dataForm.highFee,
              'weighFee': this.dataForm.weighFee,
              'loadFee': this.dataForm.loadFee,
              'overtimeFee': this.dataForm.overtimeFee,
              'takeFee': this.dataForm.takeFee,
              'addFee': this.dataForm.addFee,
              'otherFee': this.dataForm.otherFee,
              'parkingFee': this.dataForm.parkingFee,
              'printFee': this.dataForm.printFee,
              'maintainFee': this.dataForm.maintainFee,
              'refuelFee': this.dataForm.refuelFee,
              'passingFee': this.dataForm.passingFee,
              'refuelCardFee': this.dataForm.refuelCardFee,
              'passingCardFee': this.dataForm.passingCardFee,
              'platformPrepayment': this.dataForm.platformPrepayment,
              'driverPrepayment': this.dataForm.driverPrepayment,
              'otherFeeRemark': this.dataForm.otherFeeRemark,
              'driverBorrowing': this.dataForm.driverBorrowing,
              'borrowingRemark': this.dataForm.borrowingRemark,
              'deductionsFee': this.dataForm.deductionsFee,
              'deductionsRemark': this.dataForm.deductionsRemark
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
  <style lang="less">
  .supplier-cost-pop {
    .el-form-item {
      margin-bottom: 0px;
    }
    .el-dialog {
      // margin-top: 20px !important;
      .el-input__inner {
        height: 26px !important;
      }
      .el-dialog__header {
        padding: 10px 20px 10px;
      }
    }
    .el-dialog__body {
      padding: 0;
      max-height: 650px;
      overflow: auto;
    }
    .el-dialog__footer {
      padding: 10px 20px 10px;
      .el-button {
        height: 32px;
        line-height: 0;
      }
    }
    .modularBox {
      // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
      margin-left: 20px;
      margin-right: 20px;
      .el-col {
        padding-right: 0 !important;
      }
    }
    .modularBox1 {
      padding-right: 40px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .modularBox2 {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-right: 40px;
      margin-bottom: 2px;
      .addressBtn {
        width: 100%;
        padding: 9px 0px;
        text-align: center
      }
      .deleteBtn {
        width: 100%;
        height: 40px;
        padding-top: 5px;
        text-align: right;
      }
    }
    .modularBox3 {
      padding: 25px;
      .carBox {
        width: 320px;
        margin-right: 20px;
        // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
        text-align: center;
        cursor: pointer;
      }
    }
    .modularBox4 {
      padding: 25px;
    }
    .modularLabel {
      margin-bottom: 5px;
      padding-left: 20px;
      margin-top: 0px;
      span.separate {
        display: inline-block;
        width: 5px;
        margin-right: 10px;
        background: #17B3A3;
        color: transparent;
        height: 20px;
        line-height: 20px;
      }
      label {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .modularLabel2 {
      margin-top: 10px;
      line-height: 30px;
      height: 30px;
      label {
        min-width: 120px;
        float: left;
      }
    }
    .footer {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 30px;
      text-align: right;
      padding: 25px;
      .explain {
        color: #17B3A3 !important
      }
    }
  }
  </style>
